#auth .password-recovery-link {
    text-align: center;
    margin-top: 30px;
    padding-top: 20px;
    margin-bottom: 30px;
}

.login-form-field * {
    margin-bottom: 5px;
}

#toggle-password {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 5px 0 10px;
}

.password-show-icon {
    margin-right: 5px;
}

.logo {
    margin-bottom: 20px;
}

.reset-password-link {
    border-bottom: 1px solid;
    padding-bottom: 2px;
    color: var(--color-black);
}
