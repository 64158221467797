.details-membership-row {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
}

.details-membership-row .cell {
    display: flex;
    align-items: center;
}

.details-list-container.table {
    margin-top: -20px;
    flex: 1 1 auto;
    height: 100%;
}

.details-membership-row .user-group-form {
    flex: 1 1 auto;
    display: flex !important;
    flex-direction: column;
    margin-bottom: 20px;
}

.details-membership-row .filters-wrapper {
    display: flex;
}

.filters-wrapper .filters label {
    margin-bottom: 7px;
}

.details-membership-row .filter-container {
    display: flex;
    justify-content: space-between;
}

.details-entity-listing {
    flex: 1 1 auto;
    min-height: 200px;
    display: flex;
    margin-top: 10px;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
}

.details-membership-row .details-list-container {
    background-color: var(--color-white);
}

.details-membership-row .details-inputs {
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    justify-content: space-between;
    min-height: 51px;
}

.details-membership-row .details-inputs .details-left {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.details-membership-row .details-inputs .details-left > button:first-child {
    margin-right: 20px;
}

.details-membership-row .details-inputs input {
    margin-right: 10px;
}

.details-membership-row .filter-container {
    margin-top: 10px;
    height: auto;
    flex-grow: unset;
    color: var(--color-dark-grey);
}

.details-membership-row .text-input-wrapper {
    width: 300px;
    margin-top: 16px;
    margin-right: 10px;
}

@media (max-width: 750px) {
    .details-membership-row .details-inputs {
        flex-wrap: wrap;
    }
}
