body {
    height: 0px;
    margin: 0px;
    font-family: var(--font-family) !important;
    background: var(--color-white);
}

a {
    text-decoration: none;
}

.disable-scroll {
    height: 90vh;
    overflow: overlay;
}

.label {
    display: flex;
}

.tags {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    margin-bottom: 30px;
    width: 100%;
    flex-wrap: wrap;
    overflow-y: auto;
}

.not-authorised {
    text-align: center;
    padding: 40px 0;
    font-size: 18px;
    background: var(--color-red);
    margin: 70px 50px;
    color: var(--color-white);
}

label {
    margin-bottom: 7px;
}

.group-visibility-icon > div,
.group-visibility-icon,
.bot-visiblity-icon,
.bot-visiblity-icon > div {
    display: flex;
    justify-content: center;
    align-items: center;
    height:inherit;
}
