.error-page {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    background: var(--color-secondary);
    align-items: center;
    justify-content: center;
}

.error-message {
    color: var(--color-white);
    font-size: 19px;
    line-height: 24px;
    font-weight: bold;
    margin-top: 42px;
    text-transform: uppercase;
    max-width: 500px;
    text-align: center;
}

.error-link {
    color: var(--color-white);
    border: 1px solid var(--color-white);
    padding: 5px 8px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    font-size: 12px;
}

.error-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.error-wrapper {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    height: 100vh;
    align-items: center;
    justify-content: center;
    color: var(--color-white);
}
