.card-details-container {
  padding: 10px;
}

.card-details {
  margin-bottom: 40px;
}

.card-detail {
  margin-bottom: 10px;
  color: var(--color-black-80);
  font-size: 14px;
  display: flex;
  font-weight: normal;
}

.card-detail-title {
  font-weight: bold;
  min-width: 90px;
}

.card-details-actions button {
  margin-left: 5px
}

.card-details-actions button:first-child {
  margin-left: 0px;
}
