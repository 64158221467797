.details-edit-container {
    background: var(--color-light-gray);
    margin-top: 15px;
}

.details-edit-container .header-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 10px;
    padding-left: 25px;
    padding-right: 25px;
}

.details-edit-container .header {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.7px;
    color: var(--color-primary);
}


.edit-form-fields {
    padding: 10px 25px 0px 25px;
    display: flex;
    flex-direction: row;
}

.edit-form-fields .edit-fields-left .add-entity-field:last-of-type {
    margin-bottom: 0px !important;
}

.edit-form-fields .side {
    margin-left: 20px;
}

.edit-form-fields .roles {
    display: flex;
    flex-direction: column;
    width: 180px;
    background-color: var(--color-white);
    padding-top: 10px;
    border: solid 1px rgba(34, 36, 38, 0.15);
    margin-bottom: 14px;
    margin-top: 3px;
}

.roles .role {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    margin-left: 13px;
    margin-bottom: 10px;
    height: 18px;
    line-height: 18px;
    color: var(--color-grey) !important;
    font-weight: normal;
    align-items: center;
    width: auto;
}

.roles .role span {
    left: unset;
    right: 8px;
}

.roles .role svg {
    width: 26px;
    height: 22px;
}

.status span::after {
    left: unset !important;
    right: 30px;
}

.show-password {
    font-size: 14px;
    color: var(--color-primary);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin: 5px 0 0 0;
}

.show-password:hover {
    cursor: pointer;
}

.show-password span {
    margin-left: 5px;
}

.role-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.role-icon-wrapper svg {
    margin-right: 5px;
}

.details-edit-container .form-message-container .message-box {
    height: 40px;
    padding: 0px 20px !important;
    width: auto;
    line-height: 40px;
    border-radius: 0px;
}

.edit-form-fields .lastname-field {
    margin-left: 7px !important;
    padding: 0 !important;
}

.edit-form-fields input[type="radio"] {
    margin-right: 15px;
}

.edit-user-role {
    margin-top: 15px !important;
}

.edit-user-role label {
    margin-bottom: -5px !important;
}

.edit-form-buttons {
    display: flex;
    justify-content: flex-end;
    margin: 10px 0px 10px 10px;
}

.edit-user-role .icon-down-arrow-active {
    position: relative;
    right: 20px;
    top: 30px;
    float: right;
    font-size: 8px;
}

.edit-user-role .dropdown {
    width: 100%;
}

.edit-fields-left {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.edit-form-fields .side {
    flex: 2;
    display: flex;
    flex-direction: column-reverse;
    align-content: flex-end;
}

.last-password-field {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

#edit-user-btn, #edit-bot-btn, #edit-group-btn {
    margin-right: 10px;
}

@media (max-width: 750px) {
    .edit-form-fields {
        flex-direction: column !important;
    }
    .edit-form-fields .side {
        margin-left: 0 !important;
        margin-top: 20px;
    }
    .edit-form-buttons {
        margin-top: 30px;
        justify-content: center !important;
    }
}

@media (max-width:890px) {
    .roles .role {
        font-size: 14px;
    }
}
