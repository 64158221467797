.content-wrap {
  padding: 0 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: inherit;
}

@media only screen and (max-width:1200px) {
  .filter-container {
    flex-wrap: wrap;
  }
  .list-container.table, .content-wrapper .content-wrap {
    padding: 0 30px;
  }
}
