#auth .auth-page {
    background: var(--color-secondary);
    display: flex;
    align-items: center;
    height: 100vh !important;
    width: 100%;
}

#auth .login-version {
    position: relative;
    bottom: -10px;
    text-align: center;
    color: var(--color-dark-grey);
    font-size: 12px;
}

#auth .signup .form-wrapper {
    width: 470px;
}

#auth .lastname-field {
    padding-right: 0 !important;
}

#auth .form-wrapper {
    text-align: center;
    margin: 0px auto;
    min-height: 538px;
}

#auth label {
    font-size: 14px !important;
    color: var(--black) !important;
    display: flex;
}

#auth .login-error {
    margin-top: 14px;
    margin-bottom: 14px;
}

#auth input {
    font-family: var(--font-family);
    font-size: 16px;
    font-weight: bold;
    color: var(--color-dark-grey) !important;
}

#auth .loading-screen {
    background: var(--color-secondary);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 500px auto;
    height: 100vh;
    width: 100vw;
}

#eficode-loading-logo {
    width: 200px;
    height: 100px;
}

.loading-wrapper {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    height: 100vh;
    align-items: center;
    justify-content: center;
    color: var(--color-white);
}

.loading-content {
    margin-top: 30px;
}
