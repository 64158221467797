.time-interval-wrapper {
    display: flex;
    flex-wrap: wrap;
}

.text-from, .text-to {
    display: flex;
    flex-direction: column;
}

.time-inputs {
    display: flex;
}

.banner-label {
    margin-top: 20px;
}

.banner-buttons {
    margin-top: 10px;
    padding-bottom: 20px;
    border-bottom: 1px solid black;
}

.logs-time {
    flex: 3;
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
}

.logs-time input {
    width: 155px !important;
    text-indent: 6px !important;
    margin-right: 9px;
}

.time-actions-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 22px;
}

.filter-user-input-wrapper {
    width: 300px;
    padding: 4px;
}

.action-type-wrapper {
    width: 300px;
    padding: 4px;
}

.filter-affected-target-input-wrapper {
    width: 300px;
    padding: 4px;
}

.filter-skipped-user-input-wrapper {
    width: 300px;
    padding: 4px;
}

.filter-ip-input-wrapper {
    width: 300px;
    padding: 4px;
}

.action-type-wrapper {
    justify-content: space-between;
}

.action-type-dropdown {
    margin-right: 0 !important;
}

.order-icons {
    display: flex;
    flex-direction: column;
}

.order-wrapper span {
    margin-right: 10px;
}

.filter-user-ip-wrapper {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.time-range-divider {
    display: flex;
    align-items: flex-end;
    height: 60px;
}

.tooltip {
    position: relative;
    display: inline-block;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 300px;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    top: -30%;
    left: 150%;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }

@media (max-width: 991.98px) {
    .logs-time {
        flex-direction: column;
        align-items: flex-start;
    }
    .time-range-divider {
        display: none;
    }
    .text-from, .text-to, .filter-user-input-wrapper, .filter-affected-target-input-wrapper {
        margin-bottom: 7px;
    }
    .filter-user-input-wrapper, .filter-affected-target-input-wrapper, #action-type-filter>* {
        width: 300px;
    }
    .time-actions-wrapper {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        margin-bottom: 15px;
    }
    .time-interval-wrapper {
        display: initial;
    }
    .logs-time {
        margin-bottom: 15px;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .header-container {
        display: flex;
        flex-direction: column;
    }
    .time-actions-wrapper {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        justify-content: center;
    }
}
