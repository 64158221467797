.list-row {
    margin-right: 5px;
    margin-bottom: 5px;
}

.user-role-icon {
    padding-right: 10px;
    position: relative;
    display: flex;
}

.user-role-icon .private-icon {
    position: absolute;
    bottom: -6px;
    right: 9px;
}

.user-role-icon .external-icon {
    position: absolute;
    top: -6px;
    left: 1px;
}

.details-icon {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    width: 5px;
    margin-right: 10px;
    margin-left: 5px;
}

.user-info {
    flex-grow: 10;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
