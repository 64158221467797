.top-row {
    display: flex;
    padding-left: 25px;
    min-height: 75px;
    align-items: center;
}

.top-row.locked {
    background-color: var(--color-light-grey);
}

.top-row .visibility-icon {
    margin: -13px;
    position: relative;
    top: 17px;
    left: 0px;
    width: 20px;
}

.top-row .name {
    font-size: 20px;
    font-weight: bold;
    color: var(--color-secondary);
    font-family: var(--font-family);
    line-height: 29px;
    padding: 0 10px 0 15px;
}

.top-row .name.locked {
    color: var(--color-red);
}

.top-row .name.inactive {
    color: var(--color-grey);
}

.top-row .close {
    margin-right: 25px;
    align-content: space-evenly;
    display: flex;
    flex-grow: 10;
    justify-content: flex-end;
}

.top-row .close svg {
    margin-left: 10px;
}

.top-row-span {
    border-left: solid 2px #dbdbdb;
    padding: 0 15px;
    display: flex;
    font-size: 14px;
    justify-content: center;
    align-items: center;
    font-weight: normal;
    cursor: pointer;
}

.top-row-span svg {
    margin-right: 5px;
}
