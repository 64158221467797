.tag {
    display: flex;
    align-content: center;
    justify-content: center;
    padding: 7px;
    background-color: var(--color-light-grey);
    margin-left: 10px;
    border-radius: 15px;
    margin-bottom: 10px;
}

.tag span {
    font-size: 12px;
    padding-right: 5px;
    color: var(--color-primary);
}
