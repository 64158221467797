.audit-table {
    min-height: inherit;
    max-height: inherit;
    display: flex;
    flex-direction: column;
}

.infinite-scroll {
    height: auto;
}
