#list-table {
    padding: 0 30px;
}

.list-container {
    min-height: inherit;
    max-height: inherit;
    display: flex;
    flex-direction: column;
}

@media (max-width: 991.98px) {
    .header-left>div {
        margin-bottom: 10px;
    }
}
