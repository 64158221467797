.add-entity-view {
    flex: 1;
}

.add-entity-view .form-message-container {
    height: 40px;
}

.add-entity-field {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.add-entity-field label {
    display: flex;
}

.checkbox-wrapper-label {
    margin-bottom: 0 !important;
}

.add-entity-field .is-public-checkbox label {
    margin: unset;
}
