.add-entity-details {
    display: flex;
    flex-direction: column;
    flex-grow: 10;
    max-height: 530px;
    overflow-y: auto;
    overflow-x: hidden;
}

.add-entity-details .status-message {
    border-radius: 3px;
    background-color: var(--color-green);
    text-align: center;
    height: 35px;
    line-height: 35px;
    font-family: Lato;
    font-size: 15px;
    color: var(--color-white);
    margin-bottom: 10px;
}

.add-entity-entity-details-container {
    border-radius: 3px;
    border: solid 0.7px var(--color-light-grey);
    margin-bottom: 15px;
}

.add-entity-entity-details-container .details {
    display: flex;
    padding: 15px;
    border-bottom: solid 0.7px var(--color-light-grey);
}

.details .card-details {
    flex-grow: 10;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 10px;
}

.details .card-details .entity-name {
    font-size: 14px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
}

.details {
    position: relative;
}

.details .visibility-icon {
    position: absolute;
    top: 35px;
    left: 35px;
}
