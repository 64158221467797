.page-content {
    width: 100%;
    min-height: inherit;
    max-height: inherit;
}

.filter-wrapper {
    margin-bottom: 5px;
}

.header-container {
    display: flex;
    flex-direction: column;
    padding: 20px 30px 5px;
}

.filter-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;

}

.filter-input {
    margin-right: 10px;
}

.filter-container .header-right {
    display: flex;
    flex-direction: row;
}

.filter-container .header-right-button {
    margin-top: 25px;
    margin-right: 20px;
    width: max-content;
}

.filter-container .header-right .header-right-view {
    display: flex;
    flex-direction: column;
}

.filter-container .header-left {
    display: flex;
    flex-direction: row;
    justify-self: flex-start;
    flex-wrap: wrap;
}
