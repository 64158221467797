.form-wrapper {
    text-align: center;
    margin: 0px auto;
    min-height: 415px;
    width: 500px;
}

.login-error {
    margin-top: 20px;
}

.login-error input {
    font-family: var(--font-family);
    font-size: 16px;
    font-weight: bold;
    color: var(--color-dark-grey) !important;
}

.login-link {
    margin: -10px 0 30px;
}

.login-link-text {
    color: var(--color-black);
    text-decoration: none;
}

.password-info {
    margin-left: 5px;
    padding-top: 2px;
}

.password-form-field {
    margin-bottom: 12px;
}

.signup-form {
    margin-bottom: 15px;
}
