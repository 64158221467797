.add-entity-modal-container {
    height: fit-content;
    background-color: var(--color-white);
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 20px;
}

#add-entity {
    overflow-y: auto;
}

.add-entity-modal-container h4 {
    margin: 0 0 20px 0;
    color: var(--color-primary);
}

.add-entity-form-container {
    flex: 1;
}

.add-entity-user .add-entity-user-form {
    display: flex !important;
    flex-direction: column;
    flex-grow: 10;
    margin-bottom: 35px;
}

.add-entity-group {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    flex-grow: 10;
    max-height: 550px;
}

.results {
    max-height: 400px;
    overflow-y: auto;
}

.visibility {
    padding-left: 5px;
    font-size: 14px;
    color: var(--color-dark-grey);
    align-self: flex-end;
}

.extra-info {
    padding: 15px;
    overflow: hidden;
}

.extra-info .extra {
    padding-bottom: 10px;
}

.extra-title {
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 5px;
}

.extra-content {
    font-size: 14px;
    color: var(--color-dark-grey);
}

.button-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 15px;
    height: 60px;
    border-radius: 3px;
    border: solid 0.7px var(--color-light-grey);
    cursor: pointer;
    margin-bottom: 10px;
}

.button-box-text {
    flex-grow: 10;
    padding-left: 10px;
    font-size: 14px;
    font-weight: bold;
    color: var(--black);
}

.button-box:hover {
    box-shadow: 0px 2px 5px 0px var(--color-light-grey);
}

.btn-container {
    display: flex;
    flex-grow: 10;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    margin-bottom: 35px;
    min-height: fit-content;
    min-height: -moz-fit-content;
}

