.border-line {
  height: 0px;
  border: solid 0.5px #c8c8c8;
  margin-bottom: 14px;
}
.DateRangePicker {
  width: 344px !important;
}
.DateRangePickerInput {
  width: 100%;
}
.DateRangePickerInput_arrow {
  margin: 0px 8px;
}
.DateInput {
  width: 152px;
}
.CalendarDay__selected_span, .CalendarDay__hovered_span {
  background-color: var(--color-light-yellow);
  color: var(--color-black);
  border: 1px double var(--color-light-yellow);
}
.CalendarDay__selected {
  background-color: var(--color-dark-yellow);
  border: 1px double var(--color-dark-yellow);
  color: var(--color-black);
}
.CalendarDay__default:hover {
  background-color: var(--color-light-indigo);
  color: var(--color-black);
  border: 1px double var(--color-light-indigo);
}
