.user-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    font-family: var(--font-family);
    width: 260px;
    height: 55px;
    justify-content: space-around;
    padding-left: 15px;
    border-radius: 3px;
}

.user-container:hover {
    background-color: var(--color-hover-grey);
}

.user-container .user-name {
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--color-black);
    height: 19px;
    line-height: 19px;
}

.user-container .strike-through {
    color: var(--color-black);
}

.user-container .strike-through .user-name {
    color: var(--color-black);
}

.user-container .strike-through .more-info {
    color: var(--color-dark-grey);
}

.user-container .visibility-icon {
    margin: -11px;
    position: relative;
    top: 12px;
    left: -5px;
}

.user-container .details-icon {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    width: 5px;
    margin-right: 10px;
    margin-left: 10px;
}

.user-container:last-child {
    margin: 0 0 5px;
}

.user-container .more-info {
    font-size: 14px;
    color: var(--color-dark-grey);
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/* Cards */

.cards-block {
    display: flex;
    flex-direction: column;
    justify-items: center;
    padding-top: 18px;
}

.cards {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.listing-index {
    font-size: 20px;
    font-family: var(--font-family);
    font-weight: bold;
    color: var(--color-black);
    display: flex;
    justify-content: space-between;
    line-height: 20px;
    margin-top: 2px;
}

.index-value {
    padding-right: 8px;
    margin-bottom: 5px;
}
