.edit-card {
  margin-right: 5px;
  margin-bottom: 5px;
}

.role-icon {
  padding-right: 10px;
  display: flex;
  position: relative;
}

.role-icon .bottom-floating-icon {
  position: absolute;
  bottom: -5px;
  right: 10px;
}

.role-icon .top-floating-icon {
  position: absolute;
  top: -5px;
  right: 10px;
}

.edit-card-container:hover .details-icon {
  fill: var(--color-primary)
}

.edit-card-container .details-icon {
  fill: var(--color-grey)
}

.user-info {
  flex-grow: 10;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/*  locked  */

.edit-card-container.locked {
  border: solid 1px var(--color-red);
}

.edit-card-container.locked .user-name {
  color: var(--color-red) !important;
}

.edit-card-container.locked .more-info {
  color: var(--color-red) !important;
}

/* normal */

.edit-card-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  font-family: var(--font-family);
  width: 260px;
  height: 55px;
  justify-content: space-around;
  padding-left: 15px;
}

.edit-card-container:hover {
  background-color: var(--color-hover-grey);
}

.edit-card-container .user-name {
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--black);
  height: 19px;
  line-height: 19px;
}

.edit-card-container .strike-through {
  color: var(--color-grey);
}

.edit-card-container .strike-through .user-name {
  color: var(--color-grey);
}

.edit-card-container .strike-through .more-info {
  color: var(--color-grey);
}

.edit-card-container:last-child {
  margin: 0 0 5px;
}

.edit-card-container .more-info {
  font-size: 14px;
  color: var(--color-grey);
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.details-card-popup {
  max-width: 500px !important;
  width: max-content !important;
}
