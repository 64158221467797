.change-view-wrapper {
    position: relative;
    z-index: 1000;
    display: flex;
    width: 70px;
    height: 35px;
}

#details-change-listing-type-grid, #details-change-listing-type-list {
    height: 35px;
}
