.message-box {
  min-height: 40px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.message-box.message-negative {
  color: var(--color-red);
}

.message-box.message-positive {
  color: var(--color-green);
}

.message-box.updated-entity {
  background-color: var(--color-green);
  color: var(--color-white);
}

.message-box .message-header {
  font-weight: 700;
  font-size: 16px;
}
