.content-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    min-height: calc(100vh - 80px);
}

.content-wrap {
    padding: 0 30px;
    width: auto;
}

#audits .table-header, #audits .table-row {
    display: grid;
    grid-template-columns: [line1] minmax(120px, 0.5fr) [line2] minmax(120px, 0.7fr) [line3] minmax(200px, 1fr) [line4] minmax(200px, 1fr) [line5] minmax(200px, 1fr) [end] !important;
}

#audits .text-input-wrapper {
    width: 150px;
}

#filter-affected-target {
    width: 100%;
}

#filter-skipped-user {
    width: 100%;
}

#filter-ip {
    width: 100%;
}

#filter-user {
    width: 100%;
}

#audits .grid-body {
    flex: 1;
    overflow: auto;
}
