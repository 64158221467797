/* global */

.add-first-entity-wrapper {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    align-items: center;
    height: 100%;
    min-height: 150px;
    width: 100%;
}

.details-list-container.cards {
    width: 100%;
}

.add-entity-create-button {
    display: flex;
    justify-content: flex-end;
}
