/* row */

.table-row {
    border-bottom: 1px solid var(--color-hover-grey);
}

.table-row:hover {
    background-color: var(--color-hover-grey);
    cursor: pointer;
}

.table-cell {
    padding: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 14px;
    position: relative;
}

.table-cell:nth-child(1) span, .table-cell:nth-child(2) span {
    display: flex;
    align-items: center;
}

.cell-value {
    margin-left: 5px;
}
