.login-link {
    text-align: left;
    font-size: 1rem;
    margin: -10px 0 30px;
}

.recover-password-email-field {
    margin-bottom: 15px;
}

#back-to-login {
    margin-right: 5px;
}
