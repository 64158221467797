.details-info-row .details-row {
    display: flex;
    flex-direction: column;
    padding-left: 25px;
    padding-right: 30px;
    line-height: 16px;
}

.details-info-row .col {
    margin-right: 60px;
    display: flex;
    padding: 5px 0;
}

.details-info-row .head {
    font-family: Lato;
    font-size: 14px;
    color: var(--color-dark-grey);
    font-weight: bold;
    width: 100px;
}

.details-info-row .value {
    font-family: Lato;
    font-size: 14px;
    font-weight: normal;
    color: var(--color-dark-grey);
}

.details-info-row .locked {
    display: flex;
}

.details-info-row .unlock-button {
    margin-left: 5px;
    cursor: pointer;
}
