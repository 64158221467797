/* container */

.grid-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.private-icon-table {
    position: absolute !important;
    bottom: 4px;
    left: 30px;
}

.admin-icon-table {
    position: absolute !important;
    top: 6px;
    left: 30px;
}

.grid-body div {
  width: inherit;
}

/* header */

.details-entity-listing .table-header {
    background-color: var(--color-white);
    padding-top: 10px;
}

.table-header {
    border-bottom: 1px solid var(--color-light-grey);
    background-color: var(--color-white);
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 3;
    font-size: 15px;
}

.table-header, .table-row {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: [line1] minmax(180px, 1.8fr) [line2] minmax(100px, 1.3fr) [line3] minmax(100px, 1.4fr) [line4] minmax(100px, 1fr) [line5] minmax(100px, 1fr) [end];
    grid-template-columns: [line1] minmax(180px, 1.8fr) [line2] minmax(100px, 1.3fr) [line3] minmax(100px, 1.4fr) [line4] minmax(100px, 1fr) [line5] minmax(100px, 1fr) [end];
}

.table-header .table-cell {
    font-size: 16px;
    font-weight: 700
}
