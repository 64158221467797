#entity-details-view>div:nth-of-type(1)>svg {
    display: none;
}

.details-view-container {
    display: flex;
    flex-direction: column;
    width: 97vw;
    height: 90vh;
    background-color: var(--color-white);
    margin: 0px;
}

.details-view-container .filter-container {
    flex-direction: row;
    display: flex;
    font-size: 14px;
    margin-bottom: 10px;
    min-height: 35px;
}

.details-view-content {
    overflow: auto;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.details-view-container .change-view-wrapper {
    width: 70px !important;
}

.tabs-wrapper {
    margin: 28px;
}
